import { useCallback, useEffect } from "react";
import { AxiosError, HttpStatusCode } from "axios";
import { PublicClientApplication } from "@azure/msal-browser";
import { CacheRequestConfig } from "axios-cache-interceptor";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "./hooks";
import { axiosClient } from "../api";
import {
  API_METHODS,
  COMPANY_HEADER_KEY,
  LOCALE_HEADER_KEY,
  MAX_AXIOS_RETRIES,
  RETRY_HEADER_KEY,
  RETRY_WAIT_IN_MS,
} from "../constants";
import { IPCD } from "../interfaces";
import { authActions } from "../features/auth/store/AuthSlice";
import {
  loginAction,
  logoutAction,
} from "../features/auth/store/AuthSagaActions";
import {
  selectIsAuthInitializing,
  selectIsAuthInterceptorInitialized,
} from "../features/auth/store/AuthSelectors";
import { getSelectedCompany } from "../helpers/getSelectedCompany";

export const useInitInterceptor = (
  pcd?: IPCD,
  instance?: PublicClientApplication,
) => {
  const { i18n } = useTranslation();
  const isAuthInterceptorInitialized = useAppSelector(
    selectIsAuthInterceptorInitialized,
  );
  const isAuthInitializing = useAppSelector(selectIsAuthInitializing);
  const dispatch = useAppDispatch();

  // It is important, that this is exactly called once in the apps lifecycle.
  // That is why we need to check with the 'isAuthInterceptorInitialized' flag
  // Important: Don't forget, that it is called twice in dev-environment because of <StrictMode />
  const initAuthInterceptor = useCallback(() => {
    if (
      !pcd ||
      !instance ||
      isAuthInitializing ||
      isAuthInterceptorInitialized
    ) {
      return;
    }

    axiosClient.interceptors.request.use((config) => {
      const selectedCompany = getSelectedCompany();
      if (selectedCompany) {
        config.headers.set(COMPANY_HEADER_KEY, selectedCompany);
      }

      if (config.method?.toUpperCase() !== API_METHODS.POST) {
        return config;
      }
      config.headers.set(LOCALE_HEADER_KEY, i18n.language);
      return config;
    });

    axiosClient.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        if (
          error?.response?.status !== HttpStatusCode.Forbidden &&
          error?.response?.status !== HttpStatusCode.Unauthorized
        ) {
          return Promise.reject(error);
        }

        const account = instance?.getActiveAccount() || undefined;
        const reqConfig = error.config as CacheRequestConfig;

        if (!instance || !account || !reqConfig) {
          dispatch(
            logoutAction({
              msalInstance: instance,
            }),
          );

          return Promise.reject(error);
        }

        let retry = Number(reqConfig?.headers?.[RETRY_HEADER_KEY] ?? 0);

        if (retry < MAX_AXIOS_RETRIES) {
          dispatch(
            loginAction({
              account,
              msalInstance: instance,
              scopes: pcd?.config.msal.scopes ?? [],
            }),
          );

          retry += 1;

          // Giving the retry some time
          await new Promise((resolve) => {
            setTimeout(resolve, RETRY_WAIT_IN_MS);
          });

          const retryResponse = await axiosClient({
            method: reqConfig.method,
            url: reqConfig.url,
            data: reqConfig.data,
            headers: {
              [RETRY_HEADER_KEY]: retry,
            },
          });

          return retryResponse;
        }

        dispatch(logoutAction({ msalInstance: instance }));

        return Promise.reject(error);
      },
    );

    dispatch(
      authActions.setIsAuthInterceptorInitialized({
        isAuthInterceptorInitialized: true,
      }),
    );
  }, [
    instance,
    i18n.language,
    isAuthInitializing,
    isAuthInterceptorInitialized,
  ]);

  useEffect(() => {
    initAuthInterceptor();
  }, [instance, isAuthInitializing]);

  return { isAuthInterceptorInitializing: !isAuthInterceptorInitialized };
};

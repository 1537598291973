import { LOCAL_STORAGE_COMPANY_KEY } from "../constants";
import { addSentryLog } from "../features/sentry/helpers/addSentryLog";
import { SENTRY_LOG_LEVEL } from "../features/sentry/constants";

const GET_COMPANY_ERROR_TITLE = "Get company from local storage error";
const GET_COMPANY_ERROR_DESC = `An error occurred while getting the selected company from the local storage. Key: ${LOCAL_STORAGE_COMPANY_KEY}`;

export const getSelectedCompany = (): string | undefined => {
  try {
    const storedValue = localStorage.getItem(LOCAL_STORAGE_COMPANY_KEY);
    return storedValue || undefined;
  } catch (error) {
    addSentryLog({
      level: SENTRY_LOG_LEVEL.ERROR,
      title: GET_COMPANY_ERROR_TITLE,
      description: GET_COMPANY_ERROR_DESC,
      error,
    });
    return undefined;
  }
};

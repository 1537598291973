import { useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import { IComponent } from "../../../interfaces";
import { checkNestedUrlParams } from "../helpers/checkNestedUrlParams";
import { addSentryLog } from "../../sentry/helpers/addSentryLog";
import { SENTRY_LOG_LEVEL } from "../../sentry/constants";

const DEFAULT_URL_PROPS = undefined;
export const useControlByUrl = ({
  ...componentProps
}: IComponent): IComponent => {
  const id = componentProps.staticProps?.id as string | undefined;
  const [searchParams] = useSearchParams();

  const urlProps = useMemo(() => {
    try {
      if (!id) {
        return DEFAULT_URL_PROPS;
      }

      const stringifyParams = searchParams.get(id);
      if (!stringifyParams) {
        return DEFAULT_URL_PROPS;
      }

      // Decode any URL-encoded characters
      const decodedParams = decodeURIComponent(stringifyParams);

      // Replace single quotes with double quotes for keys and values
      const sanitizedParams = decodedParams
        .replace(/([{,])\s*([a-zA-Z0-9_]+)\s*:/g, '$1"$2":') // Enclose keys in quotes
        .replace(/:\s*([a-zA-Z0-9_]+)\s*(,|})/g, ':"$1"$2') // Enclose values in quotes
        .replace(/'/g, '"'); // Replace single quotes with double quotes for valid JSON

      const params = JSON.parse(sanitizedParams);

      return checkNestedUrlParams(params);
    } catch (error) {
      addSentryLog({
        level: SENTRY_LOG_LEVEL.ERROR,
        title: "Error in urlProps parsing",
        description: `An error occurred while parsing the "urlProps" from the URL for the component with ID "${id}". 
        Ensure the URL parameter is a valid JSON string. Here is also the "searchParams" object: ${JSON.stringify(searchParams)}`,
        error,
      });

      return DEFAULT_URL_PROPS;
    }
  }, [searchParams, id]);

  return { urlProps, ...componentProps };
};

import { Method } from "axios";
import cloneDeep from "lodash.clonedeep";
import { CacheRequestConfig } from "axios-cache-interceptor";
import { axiosClient, graphQLClient } from "../../../api";
import { IGraphQlRequest } from "../../../interfaces";
import i18n from "../../../configs/i18n";
import { isGraphQLRequest } from "../../../helpers/gql";
import { getSelectedCompany } from "../../../helpers/getSelectedCompany";
import { COMPANY_HEADER_KEY, LOCALE_HEADER_KEY } from "../../../constants";

const MUTATION = "mutation";
const OPERATION_DEFINITION = "OperationDefinition";

const RIVERSAND = "riversand";
const MONGO = "mongo";

const getGraphQLRequestOperationType = (
  gqlRequest: IGraphQlRequest,
): string => {
  const operationDefinition = gqlRequest.node.definitions[0];
  if (operationDefinition.kind === OPERATION_DEFINITION) {
    return operationDefinition.operation;
  }
  return "Invalid";
};

export const callAPI = async (
  requestConfig: CacheRequestConfig | IGraphQlRequest,
  token: string,
) => {
  const selectedCompany = getSelectedCompany();

  if (isGraphQLRequest(requestConfig)) {
    // we need to clone it, because graphql mutates the request, what could lead to an immutability error without deep cloning
    const graphQLRequest = cloneDeep(requestConfig);
    const isMutation =
      getGraphQLRequestOperationType(requestConfig) === MUTATION;
    const graphQLOperation = isMutation
      ? graphQLClient.mutation
      : graphQLClient.query;

    return graphQLOperation(graphQLRequest.node, graphQLRequest.variables, {
      requestPolicy: graphQLRequest.disableCache
        ? "network-only"
        : "cache-first",
      fetchOptions: () => {
        const headers: Record<string, string> = {
          authorization: `Bearer ${token}`,
          [LOCALE_HEADER_KEY]: i18n.language,
          "X-Source": isMutation ? RIVERSAND : MONGO,
          ...(graphQLRequest.headers as Record<string, string>),
        };

        if (selectedCompany) {
          headers[COMPANY_HEADER_KEY] = selectedCompany;
        }

        return { headers };
      },
    });
  }

  const axiosHeaders = selectedCompany
    ? { [COMPANY_HEADER_KEY]: selectedCompany }
    : {};

  return axiosClient({
    ...requestConfig,
    cache: requestConfig.cache
      ? { methods: [requestConfig.method] as Lowercase<Method>[] }
      : false,
    headers: {
      ...axiosHeaders,
    },
  });
};

import { ScrollRestoration } from "react-router-dom";
import { ReactNode } from "react";
import { useScrollToAnchor } from "../hooks/useScrollToAnchor";

interface IUrlManager {
  children: ReactNode;
}

export const UrlManager = ({ children }: IUrlManager) => {
  useScrollToAnchor();
  return (
    <>
      {children}
      <ScrollRestoration />
    </>
  );
};

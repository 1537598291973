import { IComponent } from "../interfaces";
import { useActionCommands } from "./useActionCommands";
import { useConditions } from "./useConditions";
import { useDynamicProps } from "./useDynamicProps";

export const usePCP = (component: IComponent) => {
  const { staticProps, urlProps } = component;
  const { componentActionCommands } = useActionCommands(component);
  const { componentDynamicProps } = useDynamicProps(component);
  const { componentConditionData } = useConditions(component);

  const additionalProps = {
    ...componentActionCommands,
    ...componentDynamicProps,
    ...componentConditionData,
  };

  if (urlProps) {
    return {
      data: {
        ...staticProps,
        ...urlProps,
        ...additionalProps,
      },
    };
  }
  return {
    data: {
      ...staticProps,
      ...additionalProps,
    },
  };
};

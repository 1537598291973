import { ILogout } from "../interfaces/ILogout";
import { LOCAL_STORAGE_COMPANY_KEY } from "../../../constants";
import { addSentryLog } from "../../sentry/helpers/addSentryLog";
import { SENTRY_LOG_LEVEL } from "../../sentry/constants";

const REMOVE_COMPANY_ERROR_TITLE = "Remove company from local storage error";
const REMOVE_COMPANY_ERROR_DESC = `An error occurred while removing the selected company from the local storage. Key: ${LOCAL_STORAGE_COMPANY_KEY}`;

const removeSelectedCompany = (): void => {
  try {
    localStorage.removeItem(LOCAL_STORAGE_COMPANY_KEY);
  } catch (error) {
    addSentryLog({
      level: SENTRY_LOG_LEVEL.ERROR,
      title: REMOVE_COMPANY_ERROR_TITLE,
      description: REMOVE_COMPANY_ERROR_DESC,
      error,
    });
  }
};

export const logout = ({ msalInstance }: ILogout) => {
  removeSelectedCompany();
  msalInstance.logoutRedirect();
};

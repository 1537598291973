import { AccountInfo } from "@azure/msal-browser";

export const MAX_AXIOS_RETRIES = 3;
export const RETRY_WAIT_IN_MS = 2500;
export const RETRY_HEADER_KEY = "X-Retry";
export const LOCALE_HEADER_KEY = "X-Locale";
export const COMPANY_HEADER_KEY = "X-Company";

export const NAVIGATION = {
  signIn: "sign-in",
  signInPathname: "/sign-in",
  default: "/",
};
export const PROTOCOL_URL_SPLIT_LENGTH = 2;
export const PROTOCOL_SEPARATOR = "//";
export const PATHNAME_SEPARATOR = "/";

export enum LANGUAGES {
  EN_US = "en-US",
}

export const DEFAULT_LANGUAGE = LANGUAGES.EN_US;

export const UPDATE_MDM_ENDPOINT = "/clients/entities/update";
export const CREATE_MDM_ENDPOINT = "/clients/entities/create";
export const POST_FORM_ENDPOINT = "/clients/forms/contact-form";
export const UPDATE_MDM_METHOD = "POST";
export const CREATE_MDM_METHOD = "POST";
export const DEFAULT_MDM_LOCALE = "de-DE";

export enum API_METHODS {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export const STORAGE_KEY_SEPARATOR = ".";
export const STORAGE_KEY_ARRAY_INDICATOR = "[]";
export const STORAGE_KEY_OBJECT_INDICATOR = "{}";

export const CONTACT_FORM_TYPE = "Contact";

export const initialUserContext: AccountInfo = {
  environment: "",
  homeAccountId: "",
  localAccountId: "",
  tenantId: "",
  name: "",
  username: "",
};

export const initialSnackBarProps = {
  open: false,
  alertProps: { description: { text: "" } },
};

export enum IOT_COMMANDS {
  htgPowerControlStart = "htg_power_control_start",
  htgPowerControlRestart = "htg_power_control_restart",
  htgPowerControlSuspend = "htg_power_control_suspend",
  htgPowerControlChangeParameters = "htg_power_control_change_parameters",
}

export const COMPONENT_TRANSLATIONS_NAMESPACE = "component";

export const LOCAL_STORAGE_COMPANY_KEY = "active-company";

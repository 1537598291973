import { CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material";
import { LicenseInfo } from "@mui/x-license";
import { getThemeData, Theme } from "paul-theme-package";
import { Provider as ReduxProvider } from "react-redux";
import { Suspense, useEffect } from "react";
import { PCP } from "./features/pcp";
import { store } from "./store/store";
import { LoadingIndicator } from "./features/processing-pcp";
import { LocalizedProvider } from "./localization";
import "./configs/i18n";

/* Use following line in DEV mode if you want to improve the debug experience in the console. This ignores all the
 * "warnings" and therefore provides a better overview in the browsers console in DEV mode:
 */
// console.warn = () => {};

export const App = () => {
  LicenseInfo.setLicenseKey(
    "ce93c6e5312409a18de812f55e3260b5Tz0xMDY3OTMsRT0xNzY5NjQ0Nzk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=",
  );
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  useEffect(() => {
    const favicon = document.getElementById(
      "favicon",
    ) as HTMLLinkElement | null;

    if (favicon) {
      favicon.href = prefersDarkMode
        ? "./favicon_dark.png"
        : "./favicon_light.png";
    }
  }, [prefersDarkMode]);

  return (
    <ThemeProvider theme={getThemeData(Theme.LIGHT_MODERN)}>
      <LocalizedProvider>
        <CssBaseline />
        <ReduxProvider store={store}>
          {/* Suspense necessary for translations */}
          <Suspense fallback={<LoadingIndicator />}>
            <PCP />
          </Suspense>
        </ReduxProvider>
      </LocalizedProvider>
    </ThemeProvider>
  );
};
